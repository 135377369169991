<template>
  <div class="w-full">
    <b-field v-for="(field, i) in data" :key="i" v-bind="fields[i]">
      <b-select
        :name="field.fieldToSave"
        class="w-full"
        :placeholder="field.placeHolder"
        :value="values[i]"
        :disabled="isDisabled(i)"
        @input="v => input(v, i)"
      >
        <option v-for="(item, n) in field.source" :value="item.Oid"  :key="n">{{ item.Name }}</option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    value: Object,
    fields: Object
  },
  data () {
    return {
      values: {
        country: null,
        province: null,
        district: null,
        subdistrict: null
      },
      loadings: {}
    }
  },
  methods: {
    isDisabled (type) {
      const { values } = this
      const keys = Object.keys(this.data)
      if (type === 'province' && keys.indexOf('country') !== -1) {
        return !values.country
      }
      if (type === 'district' && keys.indexOf('province') !== -1) {
        return !values.province || this.isDisabled('province')
      }
      if (type === 'subdistrict' && keys.indexOf('district') !== -1) {
        return !values.district || this.isDisabled('district')
      }
    },
    async loadSource (type) {
      const d = this.data[type]
      if (!d) return
      this.$set(this.loadings, type, true)
      const data = await this.$baseApi.get(Object.keys(this.data).reduce((p, k) => {
        p = p.replace(`{${this.data[k].fieldToSave}}`, this.values[k])
        return p
      }, d.sourceURL))
      this.$set(d, 'source', data)
      this.loadings[type] = false
    },
    loadSourceOnChange (type) {
      if (type === 'country') this.loadSource('province')
      else if (type === 'province') this.loadSource('district')
      else if (type === 'district') this.loadSource('subdistrict')
    },
    input (v, type) {
      this.$emit('input', v, this.data[type].fieldToSave)
      this.$nextTick(() => {
        if (type === 'country') {
          this.input(null, 'province')
        } else if (type === 'province') {
          this.input(null, 'district')
        } else if (type === 'district') {
          this.input(null, 'subdistrict')
        }
        if (this.values[type]) this.loadSourceOnChange(type)
      })
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        const keys = Object.keys(v)
        this.values = {
          ...keys.reduce((p, f) => {
            p[f] = v[f]
            return p
          }, {})
        }
      }
    }
  }
}
</script>

<style>

</style>
